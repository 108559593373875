<template>
  <el-card class="box-card">
    <template #header>
      <div class="card-header">
        <span>{{ t("profile.header") }}</span>
      </div>
    </template>
    <div>
      <el-row type="flex" :gutter="10" class="profile-row">
        <el-col :span="2">{{ t("profile.avatar") + ":" }}</el-col>
        <el-col :span="8">
          <img
            class="profile-avatar"
            :src="'https://avatars.githubusercontent.com/u/14888437?s=60&v=4'"
            alt=""
          />
        </el-col>
      </el-row>
      <el-row type="flex" :gutter="10" class="profile-row">
        <el-col :span="2">{{ t("profile.nickname") + ":" }}</el-col>
        <el-col :span="8">
          <span>{{ profile.users.name }}</span>
        </el-col>
      </el-row>
      <el-row type="flex" :gutter="10" class="profile-row">
        <el-col :span="2">{{ t("profile.email") + ":" }}</el-col>
        <el-col :span="8">
          <span>{{ profile.users.email }}</span>
        </el-col>
      </el-row>
      <el-row type="flex" :gutter="10" class="profile-row">
        <el-col :span="2">{{ t("profile.mobile") + ":" }}</el-col>
        <el-col :span="8">
          <span>{{ profile.users.mobile }}</span>
        </el-col>
      </el-row>
      <el-row type="flex" :gutter="10" class="profile-row">
        <el-col :span="2">{{ t("profile.remark") + ":" }}</el-col>
        <el-col :span="8">
          <span>{{ profile.users.description }}</span>
        </el-col>
      </el-row>
      <el-row type="flex" :gutter="10" class="profile-row">
        <el-button @click="editProfile">{{t('profile.edit_info')}}</el-button>
        <el-button @click="editPassword">{{t('profile.modify_password')}}</el-button>
      </el-row>
    </div>
    <ProfileDialog
      :users="profile.users"
      ref="profileDialog"
      @submit="updateProfile"
    ></ProfileDialog>

    <PasswordDialog ref="pwdDialog" @submit="updateUserPassword"></PasswordDialog>
  </el-card>
</template>

<script>
import { useI18n } from "vue-i18n";
import { getUserProfile, updateUser, updatePassword } from "@/api/user";
import { ElMessage } from "element-plus";
import { reactive, onMounted, ref } from "vue";
import ProfileDialog from "./profile_dialog.vue";
import PasswordDialog from "./pwd_dialog.vue";
export default {
  name: "profile",
  components: {
    ProfileDialog,
    PasswordDialog,
  },
  setup() {
    const { t } = useI18n();
    const profileDialog = ref(null);
    const pwdDialog = ref(null);

    const profile = reactive({
      users: {},
    });

    const getUserInfo = async () => {
      const res = await getUserProfile();
      if (res.error_code === 0) {
        console.log("users", res);
        profile.users = res.response;
      } else {
        ElMessage.error(res.error_message);
      }
    };

    const editProfile = () => {
      console.log("---");
      profileDialog.value.openProfileDialog();
    };

    const editPassword = () => {
      pwdDialog.value.openPwdDialog();
    };

    const updateProfile = async (obj) => {
      const res = await updateUser(obj);
      if (res.error_code === 0) {
        ElMessage.success(t("common.update_prompt"));
        profileDialog.value.handleClose();
        getUserInfo();
      } else {
        ElMessage.error(res.error_message);
      }
    };

    const updateUserPassword = async (obj) => {
      console.log("obj", obj);
      const res = await updatePassword(obj);
      if (res.error_code === 0) {
        ElMessage.success(t("common.update_prompt"));
        pwdDialog.value.handleClose();
      } else {
        ElMessage.error(res.error_message);
      }
    };

    onMounted(() => {
      // 获取数据
      getUserInfo();
    });
    return {
      t,
      profile,
      editProfile,
      editPassword,
      profileDialog,
      pwdDialog,
      updateProfile,
      updateUserPassword,
    };
  },
};
</script>

<style lang="scss" scoped>
.box-card {
  margin: 20px 10px 0px 10px;
}
.profile-avatar {
  width: 60px;
  height: 60px;
  border-radius: 10px;
}
.profile-row {
  padding: 10px 0px 10px 0px;
}
</style>