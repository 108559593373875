<template>
  <div>
    <el-dialog
      :title="t('profile.modify_password')"
      v-model="pwdDialogVisible"
      width="45%"
      :before-close="handleClose"
    >
      <el-form :model="ruleForm" :rules="rules" ref="pwdForm">
        <el-form-item :label="t('profile.old_pwd')" prop="old_password">
          <el-input v-model="ruleForm.old_password"></el-input>
        </el-form-item>
        <el-form-item :label="t('profile.new_pwd')" prop="new_password">
          <el-input v-model="ruleForm.new_password"></el-input>
        </el-form-item>
        <el-form-item
          :label="t('profile.new_confirm_pwd')"
          prop="confirm_password"
        >
          <el-input v-model="ruleForm.confirm_password"></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="resetForm">{{ t("common.cancel") }}</el-button>
          <el-button type="primary" @click="submitForm">{{
            t("common.save")
          }}</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { useI18n } from "vue-i18n";
import { ref, reactive, getCurrentInstance } from "vue";
export default {
  name: "pwd_dialog",
  setup() {
    const { t } = useI18n();
    const pwdDialogVisible = ref(false);
    const pwdForm = ref(null);
    const { proxy } = getCurrentInstance();

    const ruleForm = reactive({
      old_password: "",
      new_password: "",
      confirm_password: "",
    });

    const checkPassword = (rule, value, callback) => {
      const pwd = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^\w\s]).{15,}$/;
      if (value === "") {
        callback(new Error("密码不能为空"));
      } else if (!pwd.test(value)) {
        callback(
          new Error(
            "密码长度必须大于15,至少包含数字、大写字母、小写字母、特殊字符"
          )
        );
      } else {
        callback();
      }
    };

    const validatePwd = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== ruleForm.new_password) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };

    const rules = reactive({
      old_password: [
        {
          required: true,
          message: `${t("common.input_validate")}`,
          trigger: "blur",
        },
      ],
      new_password: [
        {
          validator: checkPassword,
          // message: `${t("common.input_validate")}`,
          trigger: "blur",
        },
      ],
      confirm_password: [
        {
          required: true,
          validator: validatePwd,
          trigger: "blur",
        },
      ],
    });

    const openPwdDialog = () => {
      ruleForm.value?.resetFields();
      pwdDialogVisible.value = true;
    };

    const handleClose = () => {
      ruleForm.value?.resetFields();
      pwdDialogVisible.value = false;
    };

    const resetForm = () => {
      ruleForm.value?.resetFields();
      pwdDialogVisible.value = false;
    };

    const submitForm = () => {
      pwdForm.value.validate((valid) => {
        if (valid) {
          proxy.$emit("submit", ruleForm);
        }
      });
    };

    return {
      t,
      ruleForm,
      rules,
      submitForm,
      pwdDialogVisible,
      openPwdDialog,
      handleClose,
      resetForm,
      pwdForm,
      checkPassword
    };
  },
};
</script>