<template>
  <div>
    <el-dialog
      :title="t('profile.edit_info')"
      v-model="dialogVisible"
      width="45%"
      :before-close="handleClose"
    >
      <el-form :model="ruleForm" :rules="rules" ref="profileForm">
        <el-form-item :label="t('profile.nickname')" prop="name">
          <el-input v-model="ruleForm.name"></el-input>
        </el-form-item>
        <el-form-item :label="t('profile.email')" prop="email">
          <el-input v-model="ruleForm.email"></el-input>
        </el-form-item>
        <el-form-item :label="t('profile.mobile')" prop="mobile">
          <el-input v-model="ruleForm.mobile"></el-input>
        </el-form-item>
        <el-form-item :label="t('profile.remark')" prop="description">
          <el-input v-model="ruleForm.description"></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="resetForm">{{ t("common.cancel") }}</el-button>
          <el-button type="primary" @click="submitForm">{{
            t("common.save")
          }}</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { ref, reactive, getCurrentInstance } from "vue";
import { useI18n } from "vue-i18n";
export default {
  name: "profile_dialog",
  props: {
    users: Object,
  },
  setup(props) {
    const { t } = useI18n();
    const ruleForm = reactive({
      id: 0,
      name: "",
      email: "",
      mobile: "",
      description: "",
    });
    const dialogVisible = ref(false);
    const profileForm = ref(null);
    const { proxy } = getCurrentInstance();

    const rules = reactive({
      name: [
        {
          required: true,
          message: `${t("common.input_validate")}`,
          trigger: "blur",
        },
      ],
      email: [
        {
          required: true,
          message: `${t("common.input_validate")}`,
          trigger: "blur",
        },
      ],
      //   description: [
      //     {
      //       required: true,
      //       message: `${t("common.input_validate")}`,
      //       trigger: "blur",
      //     },
      //   ],
      mobile: [
        {
          required: true,
          message: `${t("common.input_validate")}`,
          trigger: "blur",
        },
      ],
    });

    const openProfileDialog = () => {
      profileForm.value?.resetFields();
      dialogVisible.value = true;
      ruleForm.id = props.users.id;
      ruleForm.name = props.users.name;
      ruleForm.email = props.users.email;
      ruleForm.description = props.users.description;
      ruleForm.mobile = props.users.mobile;
    };

    const handleClose = () => {
      profileForm.value?.resetFields();
      dialogVisible.value = false;
    };

    const resetForm = () => {
      profileForm.value?.resetFields();
      dialogVisible.value = false;
    };

    const submitForm = () => {
      profileForm.value.validate((valid) => {
        if (valid) {
          proxy.$emit("submit", ruleForm);
        }
      });
    };

    return {
      t,
      ruleForm,
      rules,
      profileForm,
      dialogVisible,
      handleClose,
      openProfileDialog,
      resetForm,
      submitForm,
    };
  },
};
</script>